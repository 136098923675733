import { useState, useEffect } from 'react';
import client from 'utils/graphql-client';
import * as Sentry from '@sentry/nextjs';

interface ReturnType<T> {
  loading: boolean;
  error: string | undefined;
  result: T | undefined;
  refetch: () => void;
}

const useQuery = <T extends Record<string, unknown>>(
  query: string,
  options = {}
): ReturnType<T> => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [result, setResult] = useState<undefined | T>(undefined);
  const optionsKey = JSON.stringify(options);

  const request = async () => {
    setLoading(true);

    try {
      const result = await client.request(query, options);
      setResult(result);
      setError(undefined);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          error,
          query,
          options,
          userFacing: error.message || error,
          file: 'useQuery.tsx',
          function: 'useQuery',
        },
      });

      setError(error.message || error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    request();
  }, [optionsKey]);

  return { loading, error, result, refetch: request };
};

export default useQuery;
